import { default as _400nGTd0f8brCMeta } from "/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/400.vue?macro=true";
import { default as _500mLwO53s1G2Meta } from "/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/500.vue?macro=true";
import { default as contatospILjgkqbYMeta } from "/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/contato.vue?macro=true";
import { default as casas_45bahiaRvy4vd9yDIMeta } from "/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue?macro=true";
import { default as digion2A36AkhuwMeta } from "/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/credor/digio.vue?macro=true";
import { default as pontoSiEM5iqPDfMeta } from "/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/credor/ponto.vue?macro=true";
import { default as empresajc4ErcvL9CMeta } from "/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/empresa.vue?macro=true";
import { default as indexmnRpmBwu5xMeta } from "/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/index.vue?macro=true";
import { default as infoRagt9JQ6VEMeta } from "/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/info.vue?macro=true";
export default [
  {
    name: "400",
    path: "/400",
    component: () => import("/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/400.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    component: () => import("/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "contato",
    path: "/contato",
    component: () => import("/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/contato.vue").then(m => m.default || m)
  },
  {
    name: "credor-casas-bahia",
    path: "/credor/casas-bahia",
    meta: casas_45bahiaRvy4vd9yDIMeta || {},
    component: () => import("/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue").then(m => m.default || m)
  },
  {
    name: "credor-digio",
    path: "/credor/digio",
    meta: digion2A36AkhuwMeta || {},
    component: () => import("/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/credor/digio.vue").then(m => m.default || m)
  },
  {
    name: "credor-ponto",
    path: "/credor/ponto",
    meta: pontoSiEM5iqPDfMeta || {},
    component: () => import("/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/credor/ponto.vue").then(m => m.default || m)
  },
  {
    name: "empresa",
    path: "/empresa",
    component: () => import("/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/empresa.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexmnRpmBwu5xMeta || {},
    component: () => import("/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/codebuild/output/src3126358068/src/melembre-landing-page-v2/pages/info.vue").then(m => m.default || m)
  }
]